import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { useAnimation } from './useAnimation';

const Animation = ({ animationUrl, scale = 1 }) => {
  const containerRef = useRef();
  const { animationData, error } = useAnimation(animationUrl);

  useEffect(() => {
    if (containerRef.current && animationData) {
      initAnimation(animationData);
    }
  }, [animationData]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  function initAnimation(data) {
    lottie.loadAnimation({
      container: containerRef.current,
      animationData: data,
      autoplay: true,
      loop: true
    });
  }

  const style = {
    transform: `scale(${scale})`,
    width: '100%',
    height: '100%'
  };

  return <div ref={containerRef} style={style} />;
};

Animation.propTypes = {
  animationUrl: PropTypes.string,
  scale: PropTypes.number
};

export default Animation;
