import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import APIs from '@services/APIs';
import Header from '@lib/components/v2/Page/Header';
import { localizedString, isLocalizedStringDefined } from '@languages';
import './Success.style.scss';

const Success = ({ successMessage, redirect = false }) => {
  document.body.style.background = '#fff';
  const { APP_LOGO_SUCCESS = null, APP_LOGO_SF = null, APP_LOGO } = process.env;
  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO_SUCCESS || APP_LOGO_SF || APP_LOGO}`;

  const handleRedirect = () => {
    if (redirect) {
      APIs.return();
    }
  };

  let successMessageTitle = parse(localizedString('verificationComplete.title'));

  if (redirect && isLocalizedStringDefined('verificationComplete.titleRedirect')) {
    successMessageTitle = parse(localizedString('verificationComplete.titleRedirect'));
  }

  if (successMessage) {
    successMessageTitle = parse(successMessage);
  }

  return (
    <div className="success-flowv2-screen">
      <div className="feedback-link-container-success">
        <Header hideLogo />
      </div>

      <div className="wrapper">
        <div className="img-container" data-testid="cmplt-logo">
          <img alt="" src={finalLogo} />
        </div>
        <div className="heading" data-testid="cmplt-heading">
          {successMessageTitle}
        </div>
        <div className="title" onClick={handleRedirect} data-testid="cmplt-instruction">
          {redirect
            ? parse(localizedString('verificationComplete.redirectedDescription'))
            : parse(localizedString('verificationComplete.youCanCloseThisWindow'))}
        </div>
      </div>
    </div>
  );
};
Success.propTypes = {
  successMessage: PropTypes.any,
  redirect: PropTypes.bool
};
export default Success;
