/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Address from '@services/Address';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS } from '@lib/constants/addressSearchDebouncedDelay';
import { short, detailed } from './AddressFinder.form';
import classes from './AddressFinder.style.module.scss';
import { Input, Select } from '../..';

export default class AddressFinder extends Component {
  static propTypes = {
    inputType: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onShowDetailed: PropTypes.func,
    countryCode: PropTypes.string,
    showDetailed: PropTypes.bool
  };

  static defaultProps = {
    inputType: 'text',
    data: {},
    onChange: () => null,
    onShowDetailed: () => null,
    showDetailed: false
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleChange = debounce(
      this.handleChange.bind(this),
      ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS
    );
    this.handleSelect = this.handleSelect.bind(this);
  }

  /**
   * Return the component's initial state
   *
   */
  getInitialState() {
    const { data = {}, countryCode, showDetailed } = this.props;

    const {
      homeAddress,
      addressLine1,
      street_number,
      street_name,
      suburb,
      postcode,
      state_territory,
      isMatch
    } = data;

    return {
      homeAddress,
      address: homeAddress,
      addresses: {
        address: []
      },
      isMatch: countryCode === 'OTHER' ? false : isMatch,
      detailedAddress: {
        addressLine1,
        street_number,
        street_name,
        suburb,
        postcode,
        state_territory
      },
      filteredCountries: [],
      showDetailed,
      addressApiCalls: 0
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryCode } = this.props;
    const isMatch = countryCode === 'OTHER' ? false : nextProps.data.isMatch;
    this.setState({
      isMatch
    });
  }

  /**
   * Handle the change of detailed address in case match failed
   */
  handleChangeAddress = (detailedAddress) => {
    const { addressApiCalls } = this.state;
    const data = {
      ...detailedAddress,
      homeAddress: '',
      isMatch: false,
      addressApiCalls
    };

    this.props.onChange(data);
    this.setState({
      detailedAddress
    });
  };

  /**
   * Handle the change of the address
   * @param id
   * @param  {String} value
   * @return {Void}
   */
  handleChange(id, value) {
    const { addressApiCalls } = this.state;
    const { countryCode } = this.props;
    let addressApiCallsN = addressApiCalls;

    Address.find(value, countryCode).then((addresses) => {
      ++addressApiCallsN;
      this.setState({
        addressApiCalls: addressApiCallsN
      });

      const { onChange } = this.props;
      const { detailedAddress } = this.state;
      const data = {
        ...detailedAddress,
        homeAddress: value,
        isMatch: false,
        addressApiCalls: addressApiCallsN
      };

      this.setState(({ detailedAddress }) => ({
        addresses: { [id]: addresses, detailedAddress }
      }));

      onChange(data);
    });
  }

  /**
   * Handle select the address
   * @param id
   * @param  {String} address
   * @return {Void}
   */
  handleSelect(id, address) {
    const { onChange } = this.props;
    const { addressApiCalls } = this.state;
    const data = {
      homeAddress: address,
      isMatch: true,
      addressApiCalls
    };

    if (id === 'address') {
      this.setState(({ addresses }) => ({
        homeAddress: address,
        addresses: { ...addresses, address: [] }
      }));
    }

    onChange(data);
  }

  showManualFields = (e) => {
    e.preventDefault();
    this.setState({
      showDetailed: true
    });

    this.props.onShowDetailed(true);
  };

  /**
   * Generate additional fields for detailed address
   * @return
   */
  generateAddressFields() {
    const { countryCode } = this.props;

    const { detailedAddress } = this.state;
    let states = {
      AU: [
        {
          label: 'ACT',
          value: 'ACT'
        },
        {
          label: 'NSW',
          value: 'NSW'
        },
        {
          label: 'NT',
          value: 'NT'
        },
        {
          label: 'QLD',
          value: 'QLD'
        },
        {
          label: 'SA',
          value: 'SA'
        },
        {
          label: 'TAS',
          value: 'TAS'
        },
        {
          label: 'VIC',
          value: 'VIC'
        },
        {
          label: 'WA',
          value: 'WA'
        }
      ]
    };
    states = states[countryCode];

    return (
      <Validation initialValues={detailedAddress} config={detailed}>
        {({ dirty, errors, setField, submitted }) => (
          <div className={classes.detailedWrapper}>
            <div className={classes.inputGroup}>
              <Input
                type="text"
                id="addressLine1"
                label={localizedString('unitNumber')}
                className={classNames(classes.input)}
                hasError={dirty.addressLine1 || submitted ? errors.addressLine1 : null}
                onChange={(value) => {
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.addressLine1 = value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ addressLine1: value });
                }}
                value={detailedAddress.addressLine1}
              />

              <Input
                type="text"
                id="street_number"
                label={localizedString('streetNumber')}
                className={classNames(classes.input)}
                hasError={dirty.street_number || submitted ? errors.street_number : null}
                onChange={(value) => {
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.street_number = value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ street_number: value });
                }}
                value={detailedAddress.street_number}
              />
            </div>
            <Input
              type="text"
              id="street_name"
              label={localizedString('streetName')}
              className={classNames(classes.input)}
              hasError={dirty.street_name || submitted ? errors.street_name : null}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.street_name = value;
                this.handleChangeAddress(detailedAddr);
                setField({ street_name: value });
              }}
              value={detailedAddress.street_name}
            />
            <Input
              type="text"
              id="suburb"
              label={localizedString('suburb')}
              className={classNames(classes.input)}
              hasError={dirty.suburb || submitted ? errors.suburb : null}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.suburb = value;
                this.handleChangeAddress(detailedAddr);
                setField({ suburb: value });
              }}
              value={detailedAddress.suburb}
            />
            <div className={classes.inputGroup}>
              <Input
                type="text"
                id="postcode"
                label={localizedString('postcode')}
                autocomplete="none"
                hasError={dirty.postcode || submitted ? errors.postcode : null}
                onChange={(value) => {
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.postcode = value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ postcode: value });
                }}
                value={detailedAddress.postcode}
              />
              <Select
                id="state_territory"
                placeholder={localizedString('state')}
                hasError={dirty.state_territory || submitted ? errors.state_territory : null}
                options={states}
                onChange={(state) => {
                  const detailedAddr = { ...detailedAddress };
                  detailedAddr.state_territory = state.value;
                  this.handleChangeAddress(detailedAddr);
                  setField({ state_territory: state.value });
                }}
                value={
                  detailedAddress.state_territory
                    ? {
                        label: detailedAddress.state_territory,
                        value: detailedAddress.state_territory
                      }
                    : null
                }
              />
            </div>
          </div>
        )}
      </Validation>
    );
  }

  render() {
    const { ENABLE_ENTER_ADDRESS_MANUALLY = true } = process.env;
    const { inputType } = this.props;
    const { homeAddress, addresses, showDetailed, isMatch } = this.state;

    const addressesList = (id, data) => {
      if (data && data.length) {
        return (
          <ul className={classes.addresses}>
            {data.map(({ a, full_address }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li onClick={() => this.handleSelect(id, a || full_address)} key={index}>
                {a || full_address}
              </li>
            ))}
          </ul>
        );
      }
      return null;
    };

    return (
      <div className={classNames(classes.wrapper)}>
        {!showDetailed && (
          <div className={classes.inputWrapper}>
            <Validation config={short} initialValues={{ homeAddress }}>
              {({ dirty, errors, setField, submitted }) => {
                let addressError = dirty.homeAddress || submitted ? errors.homeAddress : null;
                if (
                  !showDetailed &&
                  !isMatch &&
                  dirty.homeAddress &&
                  addresses.address.length < 1
                ) {
                  addressError = localizedString('enterValidAddress');
                }
                return (
                  <Input
                    type={inputType}
                    id="homeAddress"
                    required
                    label={localizedString('residentalAddress')}
                    placeholder={localizedString(
                      'addressFinder.FLOW_V2_VERIFY_DETAILS_ADDRESS_PLACEHOLDER'
                    )}
                    hasError={addressError}
                    onChange={(value) => {
                      this.setState({ homeAddress: value });
                      setField({ homeAddress: value });

                      if (value && value.trim() !== (homeAddress || '').trim()) {
                        this.handleChange('address', value);
                      }
                    }}
                    value={homeAddress}
                    className={classes.inputElement}
                  />
                );
              }}
            </Validation>
            <span className={classes.icon}>
              <img alt="" src="images/icons/png/search.png" />
            </span>
            {ENABLE_ENTER_ADDRESS_MANUALLY && (
              <div className={classes.manual_cta}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.showManualFields(e)}
                  href="#"
                >
                  {localizedString('enterManually')}
                </a>
              </div>
            )}
            {addressesList('address', addresses.address)}
          </div>
        )}
        {showDetailed && this.generateAddressFields()}
      </div>
    );
  }
}
