import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '@lib/components/v2/Page';
import { Form } from 'calidation';
import Address from '@services/Address';
import { getResAddressValue } from '@lib/Utils';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import { Message } from '../../components';
import { Error500 } from '../../errors';
import APIs from '../../../services/APIs';
import { DataCheckContent, ChooseAnotherId } from '../../components/Contents';
import { ExitButton } from './ExitButton';

class Datacheck extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Return the component's initial state
   *
   */
  getInitialState() {
    const state = {
      loading: true,
      fatalError: null,
      errors: {},
      error: null,
      data: {},
      showDetails: false,
      showDetailedAddress: false,
      chooseDiffId: false,
      showFailedScreen: false,
      showAfterExitScreen: false
    };

    return state;
  }

  componentDidMount() {
    this.triggerDataCheck();
  }

  handleChange = (field, value) => {
    this.setState(({ data }) => ({
      data: {
        ...data,
        [field]: value
      }
    }));
  };

  triggerDataCheck = async () => {
    const { DATA_CHECK_ATTEMPT_COUNT = 2, REMOVE_ADDRESS_REVIEW } = process.env;
    this.setState({ loading: true, showDetails: false, showFailedScreen: false });
    const { documentId, isFlowV2DiffId, appConfig, onNextStep, on500Error } = this.props;
    const { data = {}, showDetailedAddress } = this.state;
    if ('addressData' in data) {
      let { addressData } = data;
      if (REMOVE_ADDRESS_REVIEW) {
        addressData = {};
      } else {
        addressData.fullAddress = showDetailedAddress
          ? getResAddressValue(addressData)
          : addressData.homeAddress;

        if (showDetailedAddress) {
          addressData = Address.formatManualAddress(addressData);
        } else {
          const { FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 = '' } = process.env;

          let addressCountry = data.countryCode;
          if (FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 !== '') {
            addressCountry = FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2;
          }
          // Validate the address
          const { addressData: addrDataValidated } = await Address.verify(
            addressData.homeAddress,
            addressCountry,
            appConfig.dataProvider
          );
          addressData = {
            ...addressData,
            ...addrDataValidated,
            addressApiCalls: addressData.addressApiCalls + 1
          };
        }
      }
      data.addressData = addressData;
    }

    APIs.callDataCheck({ data, documentId })
      .then(async ({ status, dataCheckInputs = null }) => {
        if (status !== 'success') {
          if (isFlowV2DiffId) {
            onNextStep();
            return;
          }
          const temp = { ...dataCheckInputs };
          let showManualAddress = false;
          if ('addressData' in temp && temp.addressData.manual) {
            showManualAddress = true;
          }
          this.setState({
            data: { ...temp },
            loading: false,
            showFailedScreen: true,
            showDetails: false,
            showDetailedAddress: showManualAddress
          });
          let count = 1;
          if (!sessionStorage.getItem('flow_v2_datacheck_count')) {
            sessionStorage.setItem('flow_v2_datacheck_count', count);
          } else {
            let count2 = sessionStorage.getItem('flow_v2_datacheck_count');
            count2 = parseInt(count2, 10);
            sessionStorage.setItem('flow_v2_datacheck_count', count2 + 1);
            count = count2 + 1;
          }
          if (count >= DATA_CHECK_ATTEMPT_COUNT) {
            this.setState({
              chooseDiffId: true,
              currentDoc: {
                type: dataCheckInputs.cardType
              }
            });
            // prompt to choose another ID
            // this.props.onNextStep();
          }
          return;
        }
        onNextStep();
      })
      .catch(({ message }) => {
        console.error(message);
        const fatalError = {
          component: Error500,
          props: {
            onTryAgain: () => {
              on500Error();
            }
          }
        };
        this.setState({ fatalError, loading: false });
      });
  };

  handleFormUpdate = ({ fields, isValid }) => {
    if (Object.keys(fields).length > 0) {
      this.setState({
        hasFormErrors: !isValid
      });
    }
  };

  handleChooseDiffId = (newDoc) => {
    const { onChooseDiffId } = this.props;
    onChooseDiffId(newDoc);
  };

  handleClickExit = () => {
    this.setState({ showAfterExitScreen: true, showFailedScreen: false });
  };

  render() {
    const {
      loading = true,
      fatalError,
      data,
      showDetails,
      showDetailedAddress,
      hasFormErrors,
      chooseDiffId = false,
      currentDoc,
      showFailedScreen,
      showAfterExitScreen
    } = this.state;

    const { component: Error, props: errorProps } = fatalError || {};

    const buttons = [];

    if (!loading) {
      buttons.push({
        label: 'Confirm Data',
        type: 'submit',
        disabled:
          hasFormErrors ||
          (data.addressData &&
            !data.addressData.apiDisabled &&
            !showDetailedAddress &&
            !data.addressData.isMatch)
      });
    }

    const { FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_BUTTON_ENABLED = false } = process.env;

    const selectDiffIdTitle = localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_TITLE');

    if (showFailedScreen && !loading) {
      const issueButtons = [
        {
          label: chooseDiffId
            ? localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_BUTTON_TEXT')
            : localizedString('dataCheck.FLOW_V2_DATA_CHECK_REVIEW_DATA_BUTTON_TEXT'),
          type: 'submit',
          onClick: () => this.setState({ showFailedScreen: false, showDetails: true }),
          dataTestId: 'datacheck-confirm-prompt-cta'
        }
      ];

      return (
        <div>
          <Message
            buttons={issueButtons}
            title={
              chooseDiffId
                ? selectDiffIdTitle
                : localizedString('dataCheck.FLOW_V2_DATA_CHECK_NO_MATCH_TITLE')
            }
            issue
          >
            {chooseDiffId
              ? parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_MESSAGE'))
              : parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_NO_MATCH_INFO_MESSAGE'))}
          </Message>
          {chooseDiffId && FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_BUTTON_ENABLED && (
            <ExitButton onClick={this.handleClickExit} />
          )}
        </div>
      );
    }

    if (showAfterExitScreen) {
      return (
        <Message title={selectDiffIdTitle} issue>
          {parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_MESSAGE'))}
        </Message>
      );
    }

    if (chooseDiffId && !loading) {
      return <ChooseAnotherId currentDoc={currentDoc} onNextStep={this.handleChooseDiffId} />;
    }

    return (
      <Form onUpdate={this.handleFormUpdate} onSubmit={this.triggerDataCheck}>
        {Error && <Error {...errorProps} />}
        <Page title="Data Check" buttons={buttons} onGoBack={this.handleGoBack}>
          <DataCheckContent
            showDetailedAddress={showDetailedAddress}
            onShowDetailed={() => this.setState({ showDetailedAddress: true })}
            onChange={this.handleChange}
            isLoading={loading}
            data={data}
            showDetails={showDetails}
          />
        </Page>
      </Form>
    );
  }
}

Datacheck.propTypes = {
  documentId: PropTypes.string,
  isFlowV2DiffId: PropTypes.bool,
  appConfig: PropTypes.object,
  onNextStep: PropTypes.func,
  on500Error: PropTypes.func,
  onChooseDiffId: PropTypes.func
};

export default connect(mapStateToProps, null)(Datacheck);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
