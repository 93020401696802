import React from 'react';
import APIs from '@services/APIs';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { localizedString } from '@languages';
import spinnerClasses from './Spinner.style.module.scss';
import classes from './Success.style.module.scss';

const Success = ({ redirect = true }) => {
  document.body.style.background = '#fff';
  const { APP_LOGO_SF = null, APP_LOGO, DONE_BUTTON_SF = false } = process.env;
  const logo = localStorage.getItem(`${window.location.host}_app_logo`);
  const finalLogo = logo || `images/logos/${APP_LOGO_SF || APP_LOGO}`;
  return (
    <div className={classNames(classes.wrapper, spinnerClasses.wrapperSp)}>
      <div className={classes['img-container']}>
        {(!DONE_BUTTON_SF || !redirect) && <img alt="" src={finalLogo} />}
        {DONE_BUTTON_SF && redirect && <div className={spinnerClasses.spinner1} />}
      </div>
      <div className={classes.heading}>{localizedString('verificationComplete.title')}</div>
      {(!DONE_BUTTON_SF || !redirect) && (
        <div className={classes.title}>{localizedString('great')}</div>
      )}
      {DONE_BUTTON_SF && redirect && (
        <div>
          <div className={classes.title}>{localizedString('beingRedirectedPleaseWait')}</div>
          <br />
          <br />
          <div className={classes.title}>
            {localizedString('ifNotRedirectIn5Secs')}
            <br />
            <a target="_blank" rel="noopener noreferrer" href="#" onClick={() => APIs.return()}>
              {localizedString('clickHere')}
            </a>
            {localizedString('toContinue2')}
          </div>
        </div>
      )}
    </div>
  );
};

Success.propTypes = {
  redirect: PropTypes.bool
};

export default Success;
