import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';
import { localizedString, isLocalizedStringDefined } from '@languages';

const {
  VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE = 'Australian driver licence',
  VOI_FLOW_V2_PROOF_OF_AGE = 'Proof of age card',
  VOI_FLOW_V2_AUS_MEDICARE = 'Medicare card',
  VOI_FLOW_V2_AUS_AUTO_BIRTH = 'Birth certificate',
  VOI_FLOW_V2_AUS_CERT_CITIZENSHIP = 'Citizenship certificate',
  VOI_FLOW_V2_AUS_CERT_DESCENT = 'Descent certificate',
  VOI_FLOW_V2_AUS_CENTRELINK = 'Centrelink',
  VOI_FLOW_V2_AUS_DEFENCE_FORCE = 'Defence Force',
  VOI_FLOW_V2_AVAILABLE_DOCS1 = null,
  VOI_FLOW_V2_AVAILABLE_DOCS2 = null
} = process.env;

const availableDocumentListDefault1 = [
  {
    type: 'Driver Licence',
    title: VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'AUS_IMMICARD',
    title: 'ImmiCard',
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PROOF_OF_AGE',
    title: VOI_FLOW_V2_PROOF_OF_AGE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];
const availableDocumentListDefault2 = [
  {
    type: 'Medicare',
    title: VOI_FLOW_V2_AUS_MEDICARE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'AUS_AUTO_BIRTH',
    title: VOI_FLOW_V2_AUS_AUTO_BIRTH,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'CITIZENSHIP_CERT',
    title: VOI_FLOW_V2_AUS_CERT_CITIZENSHIP,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: false
  },
  {
    type: 'AUS_CERT_DESCENT',
    title: VOI_FLOW_V2_AUS_CERT_DESCENT,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: false
  },
  {
    type: 'Centrelink',
    title: VOI_FLOW_V2_AUS_CENTRELINK,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Defence Force ID',
    title: VOI_FLOW_V2_AUS_DEFENCE_FORCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const availableDocumentList1 = VOI_FLOW_V2_AVAILABLE_DOCS1
  ? [...VOI_FLOW_V2_AVAILABLE_DOCS1]
  : [...availableDocumentListDefault1];
const availableDocumentList2 = VOI_FLOW_V2_AVAILABLE_DOCS2
  ? [...VOI_FLOW_V2_AVAILABLE_DOCS2]
  : [...availableDocumentListDefault2];

class ChooseAnotherID extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    selectedDocumentList: PropTypes.array,
    currentDoc: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      availableDocumentList: [],
      selectedDocument: null
    };

    this.handleNextStep = this.handleNextStep.bind(this);
  }

  componentDidMount() {
    const { selectedDocumentList = [] } = this.props;
    const excluded = selectedDocumentList.map((doc) => {
      return doc.type;
    });
    const allDocs = [...availableDocumentList1, ...availableDocumentList2];
    const availableDocumentList = allDocs.filter((doc1) => {
      return !excluded.includes(doc1.type);
    });
    this.setState({
      availableDocumentList
    });
  }

  handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }
    const { onNextStep } = this.props;
    const { selectedDocument } = this.state;
    if (selectedDocument) {
      onNextStep(selectedDocument);
    }
  }

  onCardClick(item, index) {
    if (item.checked) {
      return;
    }

    this.setState(({ availableDocumentList }) => ({
      availableDocumentList: availableDocumentList.map((card, i) => {
        return { ...card, checked: i === index };
      }),
      selectedDocument: availableDocumentList[index]
    }));
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { FLOW_V2_HIDE_IMAGES_IN_DOC_LIST } = process.env;

    const { currentDoc = {} } = this.props;
    const { availableDocumentList } = this.state;

    const footerButtons = [];
    footerButtons.push({
      label: localizedString('continue'),
      type: 'submit',
      onClick: this.handleNextStep,
      dataTestId: 'docs-continue'
    });

    let description = `${localizedString('chooseDifferentIdInsteadOf')} ${
      currentDoc.title || currentDoc.type
    }.`;

    if (isLocalizedStringDefined('chooseAnotherID.internationalPassportWithVisa')) {
      description += ` ${localizedString('chooseAnotherID.internationalPassportWithVisa')}`;
    }

    if (isLocalizedStringDefined('chooseAnotherID.CHOOSE_ANOTHER_ID_DESCRIPTION')) {
      description = localizedString('chooseAnotherID.CHOOSE_ANOTHER_ID_DESCRIPTION');
    }

    return (
      <DocumentListScreen
        title={localizedString('chooseAnotherID.CHOOSE_ANOTHER_ID_TITLE')}
        description={description}
        documentList={availableDocumentList}
        onClickDocumentItem={(item, index) => this.onCardClick(item, index)}
        footerButtons={footerButtons}
        hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
      />
    );
  }
}

export default ChooseAnotherID;
