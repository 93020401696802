import { getLanguage } from '@languages';

const { VOICE_PROMPTS_SYNC } = process.env;

const voicePromptsParams = [
  {
    default: true,
    language: 'en',
    ogg: 'media/audio/en-us.ogg',
    mp3: 'media/audio/en-us.mp3',
    durations: {
      position: { from: 0, time: 5 },
      smile: { from: 5, time: 2 },
      biggerSmile: { from: 5, time: 2 },
      thanks: { from: 18, time: 2 },
      ...(VOICE_PROMPTS_SYNC?.en || {})
    }
  },
  {
    default: true,
    language: 'uk',
    ogg: 'media/audio/English_UK.ogg',
    mp3: 'media/audio/English_UK.mp3',
    durations: {
      position: { from: 0, time: 5.8 },
      smile: { from: 5.8, time: 2.5 },
      thanks: { from: 22.2, time: 3 },
      ...(VOICE_PROMPTS_SYNC?.uk || {})
    }
  },
  {
    language: 'zh',
    ogg: 'media/audio/chinese.ogg',
    mp3: 'media/audio/chinese.mp3',
    durations: {
      position: { from: 0, time: 2.4 },
      smile: { from: 3.7, time: 5.1 },
      biggerSmile: { from: 6.6, time: 7.6 },
      thanks: { from: 9.6, time: 10.3 },
      ...(VOICE_PROMPTS_SYNC?.zh || {})
    }
  },
  {
    language: 'tr',
    ogg: 'media/audio/turkish.ogg',
    mp3: 'media/audio/turkish.mp3',
    durations: {
      position: { from: 0, time: 4.7 },
      smile: { from: 4.7, time: 2.2 },
      thanks: { from: 20.8, time: 2.5 },
      ...(VOICE_PROMPTS_SYNC?.tr || {})
    }
  },
  {
    language: 'kk',
    ogg: 'media/audio/kazakh.ogg',
    mp3: 'media/audio/kazakh.mp3',
    durations: {
      position: { from: 0, time: 2.75 },
      smile: { from: 2.75, time: 1.75 },
      thanks: { from: 15.1, time: 2 },
      ...(VOICE_PROMPTS_SYNC?.kk || {})
    }
  },
  {
    language: 'ru',
    ogg: 'media/audio/russian.ogg',
    mp3: 'media/audio/russian.mp3',
    durations: {
      position: { from: 0, time: 3.7 },
      smile: { from: 3.7, time: 2.4 },
      thanks: { from: 20.3, time: 2 },
      ...(VOICE_PROMPTS_SYNC?.ru || {})
    }
  },
  {
    language: 'ar',
    ogg: 'media/audio/arabic.ogg',
    mp3: 'media/audio/arabic.mp3',
    durations: {
      position: { from: 0, time: 2 },
      smile: { from: 3.05, time: 4.9 },
      biggerSmile: { from: 6.06, time: 7.9 },
      thanks: { from: 9.025, time: 10 },
      ...(VOICE_PROMPTS_SYNC?.ar || {})
    }
  },
  {
    language: 'hi',
    ogg: 'media/audio/hindi.ogg',
    mp3: 'media/audio/hindi.mp3',
    durations: {
      position: { from: 0, time: 3.2 },
      smile: { from: 4.3, time: 5.9 },
      biggerSmile: { from: 7.1, time: 8.8 },
      thanks: { from: 10, time: 10.8 },
      ...(VOICE_PROMPTS_SYNC?.hi || {})
    }
  },
  {
    language: 'jp',
    ogg: 'media/audio/japanese.ogg',
    mp3: 'media/audio/japanese.mp3',
    durations: {
      position: { from: 0, time: 2.9 },
      smile: { from: 4.3, time: 5.7 },
      biggerSmile: { from: 7.2, time: 9.1 },
      thanks: { from: 10.1, time: 11.5 },
      ...(VOICE_PROMPTS_SYNC?.jp || {})
    }
  },
  {
    language: 'es',
    ogg: 'media/audio/spanish.ogg',
    mp3: 'media/audio/spanish.mp3',
    durations: {
      position: { from: 0, time: 2.1 },
      smile: { from: 3.1, time: 4.7 },
      biggerSmile: { from: 5.2, time: 7.8 },
      thanks: { from: 8.1, time: 8.9 },
      ...(VOICE_PROMPTS_SYNC?.es || {})
    }
  },
  {
    language: 'id',
    ogg: 'media/audio/indonesian.ogg',
    mp3: 'media/audio/indonesian.mp3',
    durations: {
      position: { from: 0, time: 3.4 },
      smile: { from: 6, time: 7.7 },
      biggerSmile: { from: 10, time: 12 },
      thanks: { from: 14.8, time: 16 },
      ...(VOICE_PROMPTS_SYNC?.id || {})
    }
  },
  {
    language: 'pt',
    ogg: 'media/audio/portuguese.ogg',
    mp3: 'media/audio/portuguese.mp3',
    durations: {
      position: { from: 0, time: 2.8 },
      smile: { from: 4.1, time: 5.8 },
      biggerSmile: { from: 7.1, time: 8.6 },
      thanks: { from: 9, time: 9.9 },
      ...(VOICE_PROMPTS_SYNC?.pt || {})
    }
  },
  {
    language: 'ms',
    ogg: 'media/audio/malay.ogg',
    mp3: 'media/audio/malay.mp3',
    durations: {
      position: { from: 0.3, time: 3 },
      smile: { from: 4.3, time: 6 },
      biggerSmile: { from: 7.3, time: 9.4 },
      thanks: { from: 10.2, time: 11.3 },
      ...(VOICE_PROMPTS_SYNC?.ms || {})
    }
  },
  {
    language: 'tl',
    ogg: 'media/audio/filipino.ogg',
    mp3: 'media/audio/filipino.mp3',
    durations: {
      position: { from: 1.0, time: 3.4 },
      smile: { from: 4.8, time: 6.7 },
      biggerSmile: { from: 8.6, time: 10.6 },
      thanks: { from: 11.7, time: 12.5 },
      ...(VOICE_PROMPTS_SYNC?.tl || {})
    }
  }
];

export const getVoicePromptInfo = () => {
  const language = getLanguage();

  let promptInfo = voicePromptsParams.find((item) => item.language === language);
  if (!promptInfo) {
    promptInfo = voicePromptsParams.find((item) => item.default);
  }

  return promptInfo;
};
