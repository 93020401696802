/* eslint-disable no-console */
import axios from 'axios';

export default class APIs {
  static API_URL = process.env.API_URL || '/api/v1';

  /**
   * Make a request to upload the picture of an ID
   * @param  {Object} params     The request params fields
   * @param  {Object} axiosParams The axios request configs
   * @param API_PATH
   * @return {Promise}
   */
  static uploadImage(params, axiosParams, API_PATH = null) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user on offline');
    });
    window.addEventListener('orientationchange', () => {
      if (API_PATH) {
        source.cancel('Operation canceled by the user on orientationchange');
      }
    });

    const data = new FormData();
    Object.keys(params).forEach((field) => {
      if (params[field] instanceof Blob) {
        // When we use blob(Capture from Video Stream) for card image,
        //   it does not contain extension and this causes error on server-side(OrbitSDK integration)
        //   Should manually add extension ".jpeg"
        data.append(field, params[field], 'capture.jpeg');
      } else {
        data.append(field, params[field]);
      }
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${API_PATH || this.API_URL}/upload/image`, data, {
          cancelToken: source.token,
          ...axiosParams,
          onUploadProgress: (e) => {
            const percent = Math.floor((e.loaded * 100) / e.total);

            if (axiosParams && axiosParams.onProgress) {
              axiosParams.onProgress(percent);
            }
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('upload image failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to upload the picture of an Visa after PASSPORT
   * @param  {Object} params     The request params fields
   * @param  {Object} axiosParams The axios request configs
   * @param API_PATH
   * @return {Promise}
   */
  static uploadVisa(params, axiosParams, API_PATH = null) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });
    window.addEventListener('orientationchange', () => {
      if (API_PATH) {
        source.cancel('Operation canceled by the user on orientationchange');
      }
    });

    const data = new FormData();

    Object.keys(params).forEach((field) => {
      if (params[field] instanceof Blob) {
        // When we use blob(Capture from Video Stream) for card image,
        //   it does not contain extension and this causes error on server-side(OrbitSDK integration)
        //   Should manually add extension ".jpeg"
        data.append(field, params[field], 'capture.jpeg');
      } else {
        data.append(field, params[field]);
      }
    });

    const url = `${API_PATH || this.API_URL}/upload/image?visa=yes`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          ...axiosParams,
          onUploadProgress: (e) => {
            const percent = Math.floor((e.loaded * 100) / e.total);
            axiosParams.onProgress(percent);
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('upload visa failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to upload the face
   * @param  {Object} params     The request params fields
   * @param  {Object} axiosParams The axios request configs
   * @param API_PATH
   * @return {Promise}
   */
  static uploadSelfieImage(params, axiosParams, API_PATH = null) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });
    window.addEventListener('orientationchange', () => {
      if (API_PATH) {
        source.cancel('Operation canceled by the user on orientationchange');
      }
    });

    const data = new FormData();
    Object.keys(params).forEach((field) => data.append(field, params[field]));

    return new Promise((resolve, reject) => {
      axios
        .post(`${API_PATH || this.API_URL}/upload/selfie`, data, {
          cancelToken: source.token,
          ...axiosParams,
          onUploadProgress: (e) => {
            const percent = Math.floor((e.loaded * 100) / e.total);
            axiosParams.onProgress(percent);
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('upload selfie failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to extract the information from the ID document sent
   * @param  {Object} token The token
   * @param noBack
   * @param otherParams
   * @return {Promise}
   */
  static extractIdentifyInfo(token, noBack, otherParams = {}) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });
    window.addEventListener('orientationchange', () => {
      if (otherParams.isEngineV4) {
        source.cancel('Operation canceled by the user on orientationchange');
      }
    });

    const url = `${this.API_URL}/data/cards/${token}`;
    const params = { ...otherParams };
    if (noBack) {
      params.back = 'no';
    }

    if ('documentId' in params && !params.documentId) {
      delete params.documentId;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${url}`, {
          cancelToken: source.token,
          params
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.error('get card details failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject();
        });
    });
  }

  /**
   * Make a request to upload the video
   * @param  {Object} params     The request params fields
   * @param  {Object} axiosParams The axios request configs
   * @param API_PATH
   * @return {Promise}
   */
  static uploadVideo(params, axiosParams, API_PATH = null) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });
    window.addEventListener('orientationchange', () => {
      if (API_PATH) {
        source.cancel('Operation canceled by the user on orientationchange');
      }
    });

    const data = new FormData();
    Object.keys(params).forEach((field) => data.append(field, params[field]));

    return new Promise((resolve, reject) => {
      axios
        .post(`${API_PATH || this.API_URL}/upload/video`, data, {
          cancelToken: source.token,
          ...axiosParams,
          onUploadProgress: (e) => {
            const percent = Math.floor((e.loaded * 100) / e.total);
            if (axiosParams) axiosParams.onProgress(percent);
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('upload video failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to extract the information from the video
   * @return {Promise}
   * @param token
   */
  static verifyScans(token) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const { VOI_FLOW_V2_ADDITIONAL_DOC = true } = process.env;
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/data/face/${token}`, {
          cancelToken: source.token,
          params: { additionalInfo: VOI_FLOW_V2_ADDITIONAL_DOC }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('verify scan failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject();
        });
    });
  }

  /**
   * Make a request to get centrxi result
   * @param  {Object} params The request params fields
   * @param isOtherCard
   * @return {Promise}
   */
  static store(params, isOtherCard = false) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    let apiPath = 'details';
    if (isOtherCard) {
      apiPath = 'otherDetails';
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/store/${apiPath}`, params, {
          cancelToken: source.token,
          headers: {
            'content-type': 'application/json'
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('Failed to store details, Error:', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * mark Txn Completed
   * @param  {Object} params The request params fields
   * @return {Promise}
   */
  static markCompleted(params = {}) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const data = new FormData();
    Object.keys(params).forEach((field) => data.append(field, params[field]));

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/store/completed`, data, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Store  other card details
   * @param  {Object} params The request params fields
   * @return {Promise}
   */
  static storeOther(params) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const data = new FormData();
    Object.keys(params).forEach((field) => data.append(field, params[field]));

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/store/otherDetails`, data, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to get centrxi result
   * @param  {Object} params The request params fields
   * @return {Promise}
   */
  static storeMoreInfo(params) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/store/more`, params, {
          cancelToken: source.token,
          headers: {
            'content-type': 'application/json'
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('Failed to store details, Error:', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to call datacheck api and store
   * @param  {Object} params The request params fields
   * @return {Promise}
   */
  static callDataCheck(params) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/store/datacheck`, params, {
          cancelToken: source.token,
          headers: {
            'content-type': 'application/json'
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('Failed to store details, Error:', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Store  Check name Difference
   * @param  {Object} params The request params fields
   * @return {Promise}
   */
  static checkNameDiff() {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/data/checkNameDiff`, {}, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject(err);
        });
    });
  }

  /**
   * Get country by IP
   * @return {Void}
   */
  static country() {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/data/country`, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('check country failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject();
        });
    });
  }

  /**
   * Make a request to update status
   * @param  {Object} status
   * @param  {Object} params
   * @return {Promise}
   */
  static status(status, params) {
    console.log('APIs:status begin:', status, params);

    console.log('APIs:status Environment variable:', process.env.NODE_ENV);

    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    console.log('APIs:status Cancel Token:', CancelToken);

    const { ENABLE_STATISTICS = false } = process.env;
    const data = new FormData();

    if (params) {
      Object.keys(params).forEach((field) => {
        data.append(field, params[field]);
      });
    }

    console.log(
      'APIs:status Url:',
      `${this.API_URL}/update/${status}`,
      ' Form Data:',
      data,
      ' CancelToken:',
      source.token
    );

    return new Promise((resolve, reject) => {
      if (ENABLE_STATISTICS) {
        axios
          .post(`${this.API_URL}/update/${status}`, data, {
            cancelToken: source.token,
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(({ data }) => resolve(data))
          .catch((err) => {
            console.error(`status update failed at ${status}`, err);
            if (
              status !== 'termsAndCondition' &&
              status !== 'disclaimer' &&
              err.response.status === 401
            ) {
              // window.location.href = '/expired'
            }
            reject(err);
          });
      } else {
        resolve('');
      }
    });
  }

  /**
   * Make a request to update status
   * @param  {Object} status
   * @param  {Object} params
   * @return {Promise}
   */
  static createLivenessSession() {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    console.log('APIs:status Cancel Token:', CancelToken);

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/createLivenessSession`, {}, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
        });
    });
  }

  static language(language, params) {
    console.log('APIs:language begin:', language, params);

    console.log('APIs:language Environment variable:', process.env.NODE_ENV);

    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    console.log('APIs:language Cancel Token:', CancelToken);

    const { ENABLE_STATISTICS = false } = process.env;
    const data = new FormData();

    if (params) {
      Object.keys(params).forEach((field) => {
        data.append(field, params[field]);
      });
    }

    console.log(
      'APIs:language Url:',
      `${this.API_URL}/updateLanguage/${language}`,
      ' Form Data:',
      data,
      ' CancelToken:',
      source.token
    );

    return new Promise((resolve, reject) => {
      if (ENABLE_STATISTICS) {
        axios
          .post(`${this.API_URL}/updateLanguage/${language}`, data, { cancelToken: source.token })
          .then(({ data }) => resolve(data))
          .catch((err) => {
            console.error(`language update failed at ${language}`, err);
            if (err.response.status === 401) {
              // window.location.href = '/expired'
            }
            reject(err);
          });
      } else {
        resolve('');
      }
    });
  }

  /**
   * Check Device of User
   * @return {object}
   */
  static checkDevice() {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/device/check`, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('device check failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject();
        });
    });
  }

  /**
   * Check Transaction Type
   * @return {object}
   */
  static checkTransactionType() {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.API_URL}/checkType`, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('check trans. type failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ status: 'failed', verify: false });
        });
    });
  }

  /**
   * check detail match
   * @return {object}
   */
  static checkDetailMatch(params) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const data = new FormData();
    if (params) Object.keys(params).forEach((field) => data.append(field, params[field]));

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/detailMatch`, data, {
          cancelToken: source.token,
          headers: {
            'content-type': 'application/json'
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('failed while checking detail match', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject();
        });
    });
  }

  /**
   * Make a request to store questionnaire anwsers
   * @param  {Object} params The request params fields
   * @return {Promise}
   */
  static storeQuestionnaireAnswers(params) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/questionnaire/answers`, params, {
          cancelToken: source.token,
          headers: {
            'content-type': 'application/json'
          }
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('Failed to store questionnaire answers, Error:', err);
          if (err.response.status === 401) {
            window.location.href = '/expired';
          }
          reject(err);
        });
    });
  }

  /**
   * Make a request to update status
   * @return {Promise}
   */
  static checkApproval() {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const { API_URL } = this;
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        axios
          .get(`${API_URL}/approval`, { cancelToken: source.token })
          .then(({ data }) => {
            if (data.status !== 'pending') {
              resolve(data.status);
              clearInterval(interval);
            }
          })
          .catch((err) => {
            clearInterval(interval);
            console.error('check approval guided failed', err);
            if (err.response.status === 401) {
              // window.location.href = '/expired'
            }
            reject();
          });
      }, 1500);
    });
  }

  /**
   * Send Browser Token for the locking trasanction URL
   * @return {object}
   */
  static sendBrowserToken(browserToken) {
    let source;
    if (process.env.NODE_ENV !== 'development') {
      const { CancelToken } = axios;
      source = CancelToken.source();
      // When internet cut
      window.addEventListener('offline', () => {
        source.cancel('Operation canceled by the user.');
      });
    } else {
      source = {
        token: ''
      };
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/sendBrowserToken`, {
          cancelToken: source.token,
          browserToken
        })
        .then(({ data }) => resolve(data))
        .catch((err) => {
          console.error('send browser token failed', err);
          if (err.response.status === 401) {
            // window.location.href = '/expired'
          }
          reject();
        });
    });
  }

  /**
   * Handle the return url
   * @return {Void}
   */
  static return() {
    document.location.href = `${this.API_URL}/urls/return`;
  }

  /**
   * Handle the return url
   * @return {Void}
   */
  static cancel() {
    document.location.href = `${this.API_URL}/urls/cancel`;
  }
}
